import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Thursday is our Annual Free Thanksgiving Day WOD at 9:00am at The
Ville! Both gyms will be closed other than the 9:00am Ville class and
the 6am class at East on Friday will be cancelled. All other classes are
on as scheduled.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      